$(document).ready( () => {
  if (gon.has_some_data) {
    $('#batch-table').DataTable({
      "scrollX": true,
      "scrollY": true,
      "order": [],
      "columnDefs": [
        { "targets": [1,2,3,4,5,6,7,8,9,10,11,12,13,14], "searchable": false }
      ],
      language: {
        searchPlaceholder: 'example.com'
      }
    })
  }
})
