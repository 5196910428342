// Action types
export const SET_FILTER = "SET_FILTER";

// Actions

export const setFilter = name => ({ type: SET_FILTER, name: name })

//Reducers
const initialState = {
  fcp: true,
  ttfb: true,
  load_time: true,
  fully_loaded_time: true,
  page_size: true,
  requests_count: true
}

export const domainsFilter = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER: {
      const prevValue = state[action.name]
      return { ...state, [action.name]: !prevValue }
    }
    default: {
      return state;
    }
  }
};

