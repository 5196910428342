import React , { useEffect, useState } from 'react'
import $ from 'jquery'
import { useTimeline } from '@/app/redux/modules/waterfall'
export default function Timeline() {
  const [offset, setOffset] = useState()
  const data = useTimeline()

  useEffect(() => {
    const axis = $('.highcharts-grid.highcharts-yaxis-grid:first')
    const startLine = axis.find('path:nth-of-type(1)').offset().left
    const endLine = axis.find('path:nth-of-type(2)').offset().left

    const offsetValue = endLine - startLine
    setOffset(offsetValue)
    return
  }, [])

  // Calculate x values manually for display y columns headers ( 1.0s, 2.0s, ..etc)
  const rows = data.result.map((time, index) => {
    const key = `${time + index}`
    const offsetPx = `${offset * index}px`
    return (
      <span key={key} className="step" style={{ left: offsetPx }}>{ time }</span>
    )
  })
  return (
    <div className="step-container">
      { rows }
    </div>
  )
}

