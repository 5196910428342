class Blurb {
  constructor () {
    this.ui = {}
    this.ui.collapsed_row = '.collapse[id*=_desc]'
    this._call()
  }

  _call () {
    if ($(this.ui.collapsed_row).length <= 0) return false

    $(this.ui.collapsed_row).on('show.bs.collapse hide.bs.collapse', function(e) {
      icon = $(this).parent().find('.rounded-icon')
      iconClass = (e.type == 'show' ? 'fa-angle-up' : 'fa-angle-down')
      icon
        .removeClass('fa-angle-down')
        .removeClass('fa-angle-up')
        .addClass(iconClass)
    })
  }
}

$(document).ready( () => {
  new Blurb
})
