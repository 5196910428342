import React from 'react'
import Waterfall from './waterfall/index'
import ImageCompression from './image_compression/index'

export default function TestsShow() {
  return (<>
    <ImageCompression />
    <Waterfall />
  </>)
}

