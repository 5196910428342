import React, { useEffect, useState } from 'react'
import Header from './headers/header'
import Table from './table'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { saveWaterfall } from '@/app/redux/modules/waterfall'
import Spinner from '@/app/shared/spinner'

export default function Waterfall() {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { slug } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(saveWaterfall(slug))
      .catch(() => setError(true))
      .finally(() => setLoading(false))
    return
  }, [slug])

  if (error) { return null }

  return (
    <div className="large-container">
        <div className="element-wrapper mb-0 mt-5 pb-0">
          <h6 className="element-header" id=''>
            Waterfall view
          </h6>
          <div className="element-box">
            { loading && <Spinner /> }
            { !loading && <div className="waterfall-wrapper">
              <p>
              </p><div className="bg-white">
                <Header />
                <div className="table-responsive px-3 pb-2">
                  <Table />
                </div>
              </div>
              <p />
            </div>
            }
          </div>
        </div>
      </div>
  )
}

