import { useSelector } from 'react-redux'
import api from '@/app/api/waterfall'
// Action types
export const SET_WATERFALL = 'SET_WATERFALL'
export const TOGGLE_ROW_DETAILS = 'TOGGLE_ROW_DETAILS'
export const SET_FILTER = 'SET_FILTER'
export const WATERFALL_MOUNTED = 'WATERFALL_MOUNTED'
export const FILTER_BY_DIRECTION = 'FILTER_BY_DIRECTION'
export const FILTER_BY_COLUMN = 'FILTER_BY_COLUMN'

// Actions
export const setWaterfall = waterfall => ({ type: SET_WATERFALL, waterfall })

export const toggleRowDetails = id => ({ type: TOGGLE_ROW_DETAILS, id })
export const setFilter = text => ({ type: SET_FILTER, text })

export const setWaterfallMounted = () => ({ type: WATERFALL_MOUNTED })

export const changeDirection = () => ({ type: FILTER_BY_DIRECTION })
export const setFilterColumn = sort_column => ({ type: FILTER_BY_COLUMN, sort_column })

//Reducers

const initialState = {
  timeline: {
    result: []
  },
  rows: [],
  filterText: '',
  mounted: false,
  direction: 'asc',
	sort_column: 'load_order_index'
}

const hasText = (html, text) => {
  return html && html.includes(text)
}

const setFiltered = ({rows, filtered, detailsVisible}) => {
  return rows.map(row => {
    return { ...row, detailsVisible, filtered }
  })
}

const filterWaterfall = (state, action) => {
  const { text } = action
  const { rows } = state
  let newRows
  if (text && text.length > 0) {
    newRows = rows.map(row => {
      const { url, headers } = row
      const { request, response } = headers
      const filtered = hasText(url, text) || hasText(request, text) || hasText(response, text)
      return { ...row, detailsVisible: filtered, filtered }
    })
  } else {
    newRows = setFiltered({ rows, filtered: true, detailsVisible: false })
  }

  return {
    ...state,
    filterText: text,
    rows: newRows
  }
}

const sortByProperty = (property, direction) => {
  return function innerSort(a,b) {
    let comparison = a[property] - b[property]

		if (property === 'size_value') {
			comparison = a['size']['value'] - b['size']['value']
		}

		if (direction === 'desc') {
      comparison = comparison * -1
    }

    return comparison
  }
}

const filterBy = (state,{ direction, sort_column}) => {
  const rows = [...state.rows]

  rows.sort(sortByProperty(sort_column, direction))
  return { ...state, rows, direction, sort_column }
}

const filterbyDirection = (state) => {
  const direction = state.direction === 'asc' ? 'desc' : 'asc'
  const { sort_column } = state
  return filterBy(state, { direction, sort_column })
}

const filterByColumn = (state, action) => {
  const { direction } = state
  const { sort_column } = action
  return filterBy(state, { direction, sort_column })
}

export const waterfallList = (state = initialState, action) => {
  if (action.type === SET_WATERFALL) {
    const { rows, max, timeline } = action.waterfall
    return  {
      ...state,
      rows,
      max,
      timeline,
    }
  }

  if (action.type === TOGGLE_ROW_DETAILS) {
    const index = state.rows.findIndex(row => action.id === row.id)
    let rows = [...state.rows]
    const row = rows[index]
    rows[index] = { ...row, detailsVisible: !row.detailsVisible }
    return { ...state, rows }
  }

  if (action.type === SET_FILTER) {
    return filterWaterfall(state, action)
  }

  if (action.type === WATERFALL_MOUNTED) {
    return { ...state, mounted: true }
  }

  if (action.type === FILTER_BY_DIRECTION) {
    return filterbyDirection(state, action)
  }

	if (action.type === FILTER_BY_COLUMN) {
		return filterByColumn(state, action)
	}

  return state
}

// Selectors

const useWaterfall = () => useSelector(state => state.waterfall)
export const useRows = () => useWaterfall().rows
export const useFilteredRows = () => useRows().filter(row => row.filtered)
export const useMax = () => useWaterfall().max
export const useTimeline = () => useWaterfall().timeline
export const useStep = () => useTimeline().step
export const useFilterText = () => useWaterfall().filterText
export const useMounted = () => useWaterfall().mounted
export const useDirectionBoolean = () => useWaterfall().direction === 'asc'
export const useSortColumn = () => useWaterfall.sort_column

// Thunks

export const saveWaterfall = slug => async dispatch =>  {
  return api.index(slug).then(data => dispatch(setWaterfall(data)))
}

