import React from 'react'
import TestsTable from './table'
import api from '../api/tests'
import TestsFilter from './filter'
import TestsPagination from './pagination'

class DomainsShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tests: [],
      href: '',
      loading: true,
      page: 1
    }
    this.fetchHref = this.fetchHref.bind(this)
    this.fetchPage = this.fetchPage.bind(this)
  }

  fetchHref() {
    const { protocol, domain } = this.props.match.params
    return protocol ? protocol + '//' + domain : domain
  }

  fetchPage() {
    const params = new URLSearchParams(this.props.location.search)
    return parseInt(params.get('page')) || 1
  }

  componentDidMount() { this.fetchTests() }
  componentDidUpdate() {
    if (this.fetchHref() != this.state.href || this.fetchPage() != this.state.page) {
      if (this.state.loading === false) {
        this.setState({ loading: true })
      } else {
        this.fetchTests()
      }
    }
  }

  fetchTests() {
    const params = new URLSearchParams(this.props.location.search)
    const created_at = params.get('created_at') || ''
    const page = this.fetchPage()
    const href = this.fetchHref()

    api.index({ href, created_at, page }).then(tests => this.setState({ tests, href, page, loading: false }))
  }

  render() {
    return(
      <div>
        <TestsFilter />
        <TestsTable tests={this.state.tests} loading={this.state.loading}/>
        <TestsPagination
          page={this.state.page}
          pathname={this.props.location.pathname}
          history={this.props.history}/>
      </div>
    )
  }
}

export default DomainsShow

