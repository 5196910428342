import React from 'react'
import DomainsShow from './domains/show'
import TestsShow from './tests/show/show'
import {
  Switch,
  Route
} from 'react-router-dom';

export default function Routes() {
  return (
    <>
      <Switch>
        <Route path="/domain/:protocol//:domain" component={ DomainsShow } />
        <Route path="/domain/:domain" component={ DomainsShow } />
        <Route path="/:slug" component={ TestsShow } />
      </Switch>
    </>
  )
}

