import React from 'react'

import { connect } from 'react-redux'
import { setFilter } from '@/app/redux/modules/domains'
import { withRouter } from "react-router-dom"

class TestsFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = { searchText: '' }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  render() {
    const { fcp, ttfb, load_time, fully_loaded_time, page_size, requests_count} = this.props

    return(
      <div className="element-wrapper">
        <div className="element-box">
          <form className='form-inline justify-content-between' onSubmit={this.handleSubmit}>
            <div className="form-check form-check-inline mb-2 mb-md-0">
              <input className="form-check-input" name='fcp' type="checkbox" defaultChecked={ fcp } onChange={(e) => { this.props.setFilter(e.target.name) }}/>
              <label className="form-check-label">FCP</label>
            </div>
            <div className="form-check form-check-inline mb-2 mb-md-0">
              <input className="form-check-input" type="checkbox" name='ttfb' defaultChecked={ ttfb } onChange={(e) => { this.props.setFilter(e.target.name) }} />
              <label className="form-check-label" >TTFB</label>
            </div>
            <div className="form-check form-check-inline mb-2 mb-md-0">
              <input className="form-check-input" type="checkbox" name='load_time' defaultChecked={ load_time } onChange={(e) => { this.props.setFilter(e.target.name) }} />
              <label className="form-check-label">Load Time</label>
            </div>
            <div className="form-check form-check-inline mb-2 mb-md-0">
              <input className="form-check-input" type="checkbox" name='fully_loaded_time' defaultChecked={ fully_loaded_time } onChange={(e) => { this.props.setFilter(e.target.name) }}/>              <label className="form-check-label">Fully loaded time</label>
          </div>
            <div className="form-check form-check-inline mb-2 mb-md-0">
              <input className="form-check-input" type="checkbox" name='page_size' defaultChecked={ page_size } onChange={(e) => { this.props.setFilter(e.target.name) }} />
              <label className="form-check-label">Page Size</label>
            </div>
            <div className="form-check form-check-inline mb-2 mb-md-0">
              <input className="form-check-input" type="checkbox" name="requests_count" defaultChecked={ requests_count } onChange={(e) => { this.props.setFilter(e.target.name) }}/>
              <label className="form-check-label">Requests Count</label>
            </div>

            <div className="form-group">
              <input type="text" value={ this.state.searchText } onChange={ (e) => { this.setState({ searchText: e.target.value }) }}className="form-control" placeholder="Search by url" />
            </div>
            <div className="form-group">
              <input type='submit' className='btn btn-primary' value="Submit"/>
            </div>
          </form>
        </div>
      </div>
    )
  }

  handleSubmit(e) {
    e.preventDefault()
    const uri = '/domain/' + this.state.searchText
    this.props.history.push(uri)
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  const { fcp, ttfb, load_time, fully_loaded_time, page_size, requests_count } = state.domainsFilter
  return {
    fcp,
    ttfb,
    load_time,
    fully_loaded_time,
    page_size,
    requests_count
  }
}

export default connect(
  mapStateToProps,
  { setFilter }
)(withRouter(TestsFilter))


