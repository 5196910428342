import React from 'react';
import Routes from './routes';

import { createBrowserHistory } from 'history'
const history = createBrowserHistory();

import { Router } from 'react-router-dom';

function App() {
  return (
    <div className='container'>
      <Router history={history}>
        <Routes />
      </Router>
    </div>
  );
}

export default App;

