import React from 'react'
import Filter from './filter'
import DirectionFilter from './direction-filter'
import ColumnFilter from './column-filter'

export default function TestsHeader() {
  return (
    <div className="pl-3 pt-2 mb-1 waterfall-header">
      <div style={{ display: 'flex' }}>
				<ColumnFilter />
        <Filter />
        <DirectionFilter />
      </div>
      <div className="waterfall-header__legend">
        <div>Legend</div>
        <div className="legend-items">
          <div className="item">
            <div className="bar dns mr-1" />
            DNS
          </div>
          <div className="item">
            <div className="bar ml-2 mr-1 ssl" />
            SSL
          </div>
          <div className="item">
            <div className="bar ml-2 mr-1 wait" />
            Wait
          </div>
          <div className="item">
            <div className="bar connect mr-1 ml-2" />
            Connect
          </div>
          <div className="item">
            <div className="bar ml-2 mr-1 receive" />
            Receive
          </div>
        </div>
      </div>
    </div>
  )
}
