import { combineReducers } from 'redux';
import { domainsFilter } from './modules/domains';
import { waterfallList } from './modules/waterfall';

export default combineReducers({
  waterfall: waterfallList,
  domainsFilter
});


