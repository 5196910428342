$(document).ready( () => {
  $('[data-toggle="popover"]').each(function(){
    $(this).popover({
      content: $(this).parent().find('.summary-text').text(),
      toggle: 'popover',
      trigger: 'hover focus',
      html: true
    })
  })
})
