import React from 'react'
import PropTypes from 'prop-types'
import { useHighlight } from './highlight'
import { useFilterText } from '@/app/redux/modules/waterfall'

export default function RowDetails({ row }) {
  const { headers, detailsVisible } = row
  const { request, response } = headers

  if (!detailsVisible) {
    return null
  }

  const query = useFilterText()
  const highlight = (text) => {
    return useHighlight({ text, query })
  }

  return (
    <tr>
      <td colSpan='4'>
        <div>
          <div className='my-2'>
            <h4>Response Headers</h4>
            <span dangerouslySetInnerHTML={highlight(response) || 'No response headers' }></span>
          </div>

          <div className='my-2'>
            <h4> Request Headers</h4>
            <span dangerouslySetInnerHTML={highlight(request) || 'No request headers' }></span>
          </div>
        </div>
      </td>
      <td colSpan="0"></td>
      <td colSpan="0"></td>
      <td colSpan="0"></td>
    </tr>
  )
}

RowDetails.propTypes = {
  row: PropTypes.object
}

