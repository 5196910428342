import React from 'react'
import PropTypes from 'prop-types'
import Spinner from '@/app/shared/spinner'

export default function ImageCompressionTable({ stats, loading }) {
  const { analyzed, savings, weight } = stats

  const analyzedHtml = { __html: ' ' + analyzed }
  const savingsHtml = { __html: ' ' + savings }
  const weightHtml = { __html: ' ' + weight }
  if (Object.keys(stats).length === 0 && !loading) { return null }

  return (<div className="element-wrapper mb-0 mt-5 pb-0" id='image_compression'>
			<h6 className="element-header">
				Image compression test
			</h6>
			<div className="element-box">
        { loading && <Spinner /> }
				{ !loading && <table className="table">
					<tbody>
						<tr>
							<td className="no-border-top"><span dangerouslySetInnerHTML={analyzedHtml}></span></td>
						</tr>
						<tr>
							<td><span dangerouslySetInnerHTML={ weightHtml }></span></td>
						</tr>
						<tr>
							<td><span dangerouslySetInnerHTML={ savingsHtml }></span></td>
						</tr>
					</tbody>
				</table> }
			</div>
		</div>)
}

ImageCompressionTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  stats: PropTypes.object
}

