import React from 'react'
import PropTypes from 'prop-types'

class TestsPagination extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(type) {
    const page = type == 'next' ? this.props.page + 1 : this.props.page - 1
    const uri = this.props.pathname + '?page=' + page
    this.props.history.push(uri)
  }

  render() {
    const { page } = this.props
    const paginationClass = page > 1 ? 'justify-content-between' : 'justify-content-end'
    return(
      <div className='row my-3'>
        <div className={ 'col-3 offset-9 d-flex' + paginationClass }>
          {
            page > 1 &&
            <button className='btn btn-primary' onClick={() => this.handleClick('prev') }>Prev page</button>
          }
          <button className='btn btn-primary pull-right' onClick={() => this.handleClick('next')}>Next page</button>
        </div>
      </div>
    )
  }
}

TestsPagination.propTypes = {
  page: PropTypes.number,
  pathname: PropTypes.string,
  history: PropTypes.object
}

export default TestsPagination

