import React from 'react'
import { connect } from 'react-redux'

class Test extends React.Component {
  render () {
    const {
      url, external_url, location_flag,
      location_title, created_date
    } = this.props.test

    const {
      load_time, fully_loaded_time, page_size,
      requests_count, ttfb, fcp
    } = this.props.test.metrics

    const { domainsFilter } = this.props

    return(
      <tr>
        <td style={{overflow: 'hidden', maxWidth: '500px'}}><a target="_blank" href={ url }>{ url }</a></td>
        <td><a target="_blank" className="test-link-icon" href={ external_url }><i className="fa fa-external-link" />
          </a></td>
        <td><img src={ location_flag } title={ location_title } width="25px" />
        </td>
        <td>{ location_title }</td>
        <td>{ created_date }</td>
        { domainsFilter.load_time && <td>{ load_time }</td> }
        { domainsFilter.fully_loaded_time && <td>{ fully_loaded_time } </td> }
        { domainsFilter.page_size && <td>{ page_size }</td> }
        { domainsFilter.requests_count && <td>{ requests_count }</td> }
        { domainsFilter.ttfb && <td>{ ttfb }</td> }
        { domainsFilter.fcp && <td>{ fcp } </td> }
      </tr>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    domainsFilter: state.domainsFilter
  }
}

export default connect(
  mapStateToProps,
  null
)(Test)

