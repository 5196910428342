import React from 'react'
import { useDispatch } from 'react-redux'
import { useSortColumn, setFilterColumn } from '@/app/redux/modules/waterfall'

export default function ColumnFilter() {
	const sort_column = useSortColumn()
	const dispatch = useDispatch()

  return	(<div>
    <label htmlFor="filter_by">Sort by</label>
		<select
			value={sort_column}
			onChange={(e) => dispatch(setFilterColumn(e.currentTarget.value))}
			className="form-control"
			id="filter_by"
			style={{width: '300px', marginRight: '30px'}}>
			<option value='load_order_index'>Load Order</option>
      <option value='load_time'>Load Time</option>
			<option value='size_value'>File Size</option>
      <option value="status_code_index">Status Code</option>
		</select>
	</div>)
}

