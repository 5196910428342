import React from 'react'
import Test from './test'
import { connect } from 'react-redux'
import Spinner from '../shared/spinner'

class TestsTable extends React.Component {
  render() {
    if (this.props.loading) {
      return <Spinner />
    }

    if (this.props.tests.length == 0) {
      return 'There are no tests matched to this domain'
    }

    const testsList = this.props.tests.map(item => <Test key={item.id} test={item} />)
    const { fcp, ttfb, load_time, fully_loaded_time, page_size, requests_count } = this.props.domainsFilter

    return(
      <div className='table-responsive'>
        <table className="table response-codes">
          <thead>
            <tr>
              <th>URL</th>
              <th>Link</th>
              <th>Flag</th>
              <th>Location</th>
              <th>Created</th>
              { load_time && <th>Load Time</th> }
              { fully_loaded_time && <th>Fully loaded time</th> }
              { page_size && <th>Page size</th> }
              { requests_count && <th>Requests</th> }
              { ttfb && <th>TTFB</th> }
              { fcp && <th>FCP</th> }
            </tr>
          </thead>
          <tbody>
            { testsList }
          </tbody>
        </table>
      </div>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    domainsFilter: state.domainsFilter
  }
}

export default connect(
  mapStateToProps,
  null
)(TestsTable)

