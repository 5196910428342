import React from 'react'
import { useDirectionBoolean } from '@/app/redux/modules/waterfall'
import { useDispatch } from 'react-redux'
import { changeDirection } from '@/app/redux/modules/waterfall'

export default function DirectionFilter() {
  const dispatch = useDispatch()
  const checkedValue = useDirectionBoolean()

  return (
    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
      <label htmlFor="filter_direction">Rising</label>
      <input
        type="checkbox"
        checked={checkedValue}
        onChange={() => dispatch(changeDirection())}
        className="form-control form-control-sm bright"
        id="filter_direction"
        style={{marginTop: '25%', marginLeft: '33%'}} />
    </div>)
}

