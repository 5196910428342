import React from 'react'
import { setFilter, useFilterText } from '@/app/redux/modules/waterfall'
import { useDispatch } from 'react-redux'

export default function WaterfallFilter() {
  const dispatch = useDispatch()
  const text = useFilterText()
  return (
    <div>
      <label htmlFor="filter_text">Filter</label>
      <input id="filter_text" value={text} onChange={(e) => dispatch(setFilter(e.currentTarget.value))} className="form-control bright" type="text" />
    </div>
  )
}
