import React from 'react'
import { useDispatch } from 'react-redux'
import { toggleRowDetails } from '@/app/redux/modules/waterfall'
import PropTypes from 'prop-types'
import RowDetails from './row-details'
import Chart from './chart'
import { useHighlight } from './highlight'
import { useFilterText } from '@/app/redux/modules/waterfall'

export default function WaterfallRow({ row, max, step}) {
  const { status_icon, url, timings, size, content_type_image, id, filtered } = row
  const dispatch = useDispatch();
  const iconClass = `fa ${content_type_image}`

  const query = useFilterText()
  const textWithHighlight = useHighlight({ text: url, query })

  return (
    <>
      <tr className={ filtered ? '' : 'd-none' }>
        <td className='url'>
          <i className={iconClass + ' tooltip-status-icon'} >
            <div className={`status-dot mx-1 ${status_icon}`}></div>
          </i>
          <a title={url} href={url} target='_blank' rel="noreferrer" className='tooltip-wrapper'>
            <span dangerouslySetInnerHTML={textWithHighlight}></span></a>
        </td>
        <td className='size'>{ size.title }</td>
        <Chart max={max} step={step} timings={timings}/>
        <td>
         <button className="btn btn-xs" onClick={() => dispatch(toggleRowDetails(id))} style={{ backgroundColor: 'transparent' }}>
          <i className="fa fa-th-list" />
         </button>
        </td>
      </tr>
      <RowDetails row={row}/>
    </>
  )
}

WaterfallRow.propTypes = {
  max: PropTypes.number,
  step: PropTypes.number,
  row: PropTypes.object,
  visible: PropTypes.bool
}

