import React, { useEffect, useState } from 'react'
import Table from './table'
import api from '@/app/api/image_compression'
import { useParams } from 'react-router-dom'

export default function ImageCompression() {
  const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)
	const [stats, setStats] = useState({})
	const { slug } = useParams()

	useEffect(() => {
		api.image_compression(slug)
			.then(result => setStats(result || {}))
			.catch(() => setError(true))
			.finally(() => setLoading(false))
		return
	}, [])

  if (error) { return null }
  return <Table
		loading={loading}
    stats={stats}
  />
}

