window.isBatch = ->
  $('form.batches-block').length > 0

class window.HomePage
  constructor: ->
    @ui = {}
    @ui.form = 'form.page-block'
    @ui.loading_block = '.loading-block'
    @ui.error = '.error-wrapper'
    @ui.results = '.result-block'
    @ui.input_url = 'input#page_url'
    @ui.recaptcha_field = '#page_recaptcha_response'
    @_call()

  _call: ->
    $(@ui.form).on('ajax:success', (event,data,status,xhr) =>
      window.location.href = event.detail[0]['path']
    ).on('ajax:error', (xhr, data, status, error) =>
      @_enableForm()
      @_hideLoadingBlock()
      @_showError(xhr.detail[0].error)
    )

    $(@ui.form).submit (e) =>
      if $(@ui.input_url).val() == ''
        return false
      else
        @_showLoading()

      if @ui.recaptcha_field && $(@ui.recaptcha_field).val().length <= 0
        @_waitRecaptchaAndSubmit()
        false

  _submitForm: =>
    Rails.fire($(@ui.form)[0], 'submit')

  _waitRecaptchaAndSubmit: =>
    grecaptcha.execute(gon.recaptcha_site_key, action: 'homepage').then ((token) =>
      $(@ui.recaptcha_field).val(token)
      @_submitForm()
    ), (_fail) ->
      @_submitForm()
    @_showLoading()

   _hide: (el) ->
     $(el).addClass('d-none')

   _show: (el) ->
     $(el).removeClass('d-none')

   _showError: (text) ->
     $(@ui.error).text(text)
     @_show(@ui.error)

   _hideError: ->
     @_hide(@ui.error)

   _showLoadingBlock: ->
     @_show(@ui.loading_block)

   _hideLoadingBlock: ->
     @_hide(@ui.loading_block)

    _disableForm: ->
      $(@ui.form).css('opacity', 0.5)
      $("#{@ui.form} .btn").prop('disabled', true)
      $("#{@ui.input_url}").prop('readonly', true)
      $("#{@ui.form} select").css('pointer-events', 'none')

    _enableForm: ->
      $(@ui.form).css('opacity', 1)
      $("#{@ui.form} .btn").prop('disabled', false)
      $("#{@ui.input_url}").prop('readonly', false)
      $("#{@ui.form} select").css('pointer-events', 'auto')

    _showLoading: ->
      unless gon.has_some_data
        $('.result-block').addClass('d-none')
      @_disableForm()
      @_hideError()
      @_showLoadingBlock()

class window.Results extends HomePage
  constructor: ->
    super()

  _call: ->
    return unless @_resultPage()

    return if gon.has_data
    @_showLoading()

    channel = if isBatch() then 'BatchChannel' else 'TestChannel'

    if isBatch()
      ActionCable.createConsumer('/websocket').subscriptions.create channel: 'BatchProgressChannel', slug: gon.slug,
        received: (data) =>
          @_showProgress(data)
    else
      setTimeout(@_reload, 30000)

  _reload: ->
    location.reload()

  _showProgress: (data) =>
    unless data.show
      $('.os-progress-bar').addClass('d-none')
      return
    $('.bar-label-right span').text(data.fraction)
    $('.bar-level-2').css('width', data.percentage)
    $('.os-progress-bar').removeClass('d-none')

    if window.initialDataReceived
      @_reload()
    window.initialDataReceived = true

  _resultPage: ->
    $(@ui.results).length > 0

class BatchPage extends HomePage
  constructor: ->
    @ui = {}
    @ui.form = 'form.batches-block'
    @ui.loading_block = '.loading-block'
    @ui.error = '.error-wrapper'
    @ui.results = '.result-block'
    @ui.input_url = '#form_urls'
    @ui.recaptcha_field = null
    @_call()

$ ->
  if isBatch() then new BatchPage else new HomePage
  new Results
