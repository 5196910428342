import React, { useEffect } from 'react'
import Timeline from './headers/timeline'
import Row from './rows/row'
import { useFilteredRows, useRows, useMax, useStep, setWaterfallMounted, useMounted } from '@/app/redux/modules/waterfall'
import { useDispatch } from 'react-redux'

export default function TestsTable() {
  const filtered = useFilteredRows()
  const max = useMax()
  const step = useStep()
  const rows = useRows()
  const dispatch = useDispatch()
  const mounted = useMounted()
  useEffect(() => {
    dispatch(setWaterfallMounted())
    return
  }, [])
  return (
    <table className="table table-sm waterfall-table">
      { filtered.length > 0 && <thead>
        <tr>
          <th scope="col">File</th>
          <th scope="col">Size</th>
          <th className="zero-padding-bottom timeline-th" scope="col">
             <div className="timeline-header">
              { mounted && <Timeline /> }
             </div>
          </th>
          <th></th>
       </tr>
      </thead>
      }
      <tbody>
        {
          filtered.length === 0 &&
          <tr><td><p className='my-2'>No results found, please cleanup your search and try again</p></td></tr>
        }
        {
          rows.map(row => (
            <Row key={row.id}
              row={row}
              max={max}
              step={step}/>
          ))
        }
      </tbody>
    </table>)
}

