import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_more from 'highcharts/highcharts-more'
import PropTypes from 'prop-types'
HC_more(Highcharts)

function WaterfallChart({ max, step, timings }) {
  const min = 0
  const tooltip = timings.tooltip
  const series = timings.series
  const tickInterval = step
  const options = {
    chart: {
      type: 'columnrange',
      inverted: true,
      margin: [0, 0, 0, 0],
      spacing: [0, 0, 0, 0]
    },
    title: { text: null },
    credits: { enabled: false },
    exporting: { enabled: false },
    tooltip: {
      formatter() {
        return tooltip
      },
      outside: true,
      hideDelay: 0
    },
    yAxis: {
      min,
      max,
      tickInterval: tickInterval,
      labels: { enabled: false },
      title: { text: null },
      reversedStacks: false
    },
    legend: { reversed: false },
    plotOptions: {
      series: {
        minPointLength: 3,
        stacking: 'overlap',
        groupPadding: 0
      }
    },
    colors: ['#f699c1', '#9871b5', '#34c2d0', '#fde448', '#8ac730', '#ccc'],
    series
  }
  return (
    <td className='waterfall'>
          <HighchartsReact
            containerProps = {{ className: 'waterfall-chart' }}
            highcharts={Highcharts}
            options={options}
          />
    </td>
  )
}

WaterfallChart.propTypes = {
  timings: PropTypes.object,
  max: PropTypes.number,
  step: PropTypes.number
}

export default React.memo(WaterfallChart, () => true)

